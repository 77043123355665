.certificate-container {
    display: flex;
    flex-direction: column;
    padding: 5% 10%;
    text-align: left;
}

.certificate-container .certificate-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
}

.certificate-card {
    display: flex;
    flex-direction: row;
}

.certificate-head {
    display: flex;
    flex-direction: row;
    // padding: 5% 10%;
    // text-align: left;
}

.certificate-details {
    margin-left: 10px;
}

.certificate-details p {
    margin: 5px 0;
    margin-top: 0;
    font-size: 0.9rem;
}

.certificate-details a {
    color: #c3ddf7;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: transform 0.2s ease-in-out;
    margin: 0 auto;
    padding: 10px 20px;
    display: inline-block; /* Ensures the transform works as expected */
    // background-color: #2b6cb0; /* Optional for better visibility */
    text-align: center; /* Centers text inside */
}

.certificate-details a:hover {
    transform: scale(1.05); /* Scales the anchor slightly on hover */
}

.certificate-img img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-top: 30px;
}


// ---------------------------------------------------
.projects-container {
    display: flex;
    flex-direction: column;
    padding: 5% 10%;
    text-align: left;
}

.projects-container .projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
}

.projects-container .projects-grid .project {
    text-align: left;
}

.project h2:hover {
    text-decoration: underline;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.zoom {
    border-radius: 5px;
    transition: transform .2s;
    margin: 0 auto;
}

.zoom:hover {
    transform: scale(1.05);
}

@media screen {
    @media (max-width: 1440px) {
        .certificate-container .certificate-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 50px;
        }
    }

    @media (max-width: 1024px) {

        .certificate-container {
            display: block;
            padding-left: 5%;
            padding-right: 5%;
        }

        .certificate-container .certificate-grid {
            display: block;
        }

        .certificate-container .certificate-grid .certificate-card {
            padding-bottom: 5%;
        }

        .projects-container {
            display: block;
            padding-left: 5%;
            padding-right: 5%;
        }

        .projects-container .projects-grid {
            display: block;
        }

        .projects-container .projects-grid .project {
            padding-bottom: 5%;
        }
    }


}