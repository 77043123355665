.certifications-container {
  padding: 20px;
}

.certifications-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.cert-card {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 320px;
  background-color: #fff;
}

.cert-img img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.cert-details {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cert-details h2 {
  margin: 0;
  font-size: 1.2rem;
}

.cert-details p {
  margin: 5px 0;
  font-size: 0.9rem;
}

.cert-details a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.cert-details a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cert-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .cert-img img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .certifications-grid {
    flex-direction: column;
  }

  .cert-card {
    width: 100%;
  }
}